import moment from 'moment';
import { sellerBiddingActions as actionTypes, errorMessages, constants, roles } from '../constants';
import { bwicService, biddingService, daysService } from '../services';
import { biddingActions, errorActions, colorDistributionActions, notificationActions } from '.';
import { biddingUtils, numericUtils } from '../utils';
import { feedbackTypes } from '../constants/bidding';
import { controlPanelActions } from './control-panel.actions';
import { dealerListPanelActions } from './dealer-list-panel.actions';
import { bidOnBehalfActions } from './bid-on-behalf.actions';
import { BwicStatus } from '../types/enums/BwicStatus';
import { apiOperationActions } from './api-operation.actions';
import { ApiOperationType } from '../types/api-operation/ApiOperationType';
import { user } from '../user';
import { favoriteBrokerDealersActions } from './favorite-broker-dealers.actions';
import { getSecuritiesWithLatestBids } from '../selectors';
import { dealerListActions } from './dealer-list.actions';
import { BwicProcessType } from '../types/models/Process';
import { OpenBiddingStatus } from "../types/enums/OpenBiddingStatus";
import { BidLevel } from '../types/bidding/BidLevel';

export const sellerBiddingActions = {
    init,
    cancelBwic,
    reset,
    tradeAll,
    completeBwic,
    setTargetLevel,
    sendTargetLevel,
    trade,
    tradeConfirm,
    sendQuickFeedback,
    tradingPositionSelectionChange,
    tradeAllSelectionChange,
    tradingPositionSettleDateChange,
    tradingPositionCommentChange,
    tradingPositionCompanyChange,
    tradingPositionBidChange,
    submitTrade,
    cancelTrade,
    showBidList,
    toggleBidList,
    sendBidRequest,
    markBidsShown
}

function init(bwic) {
    return async dispatch => {
        const year = new Date().getFullYear();

        try {
            const nextBusinessDayPromise = daysService.getNextBusinessDate();
            const todayIsBusinessDayPromise = daysService.isBusinessDay(new Date());
            const currentYearHolidaysPromise = daysService.getHolidays(year);
            const nextYearHolidaysPromise = daysService.getHolidays(year + 1);
            const isBidingOrFinished = (bwic.status === BwicStatus.bidding || bwic.status === BwicStatus.finished);
            if (!bwic.isAllToAll &&  isBidingOrFinished && bwic.process.type !== BwicProcessType.Live) {
                dispatch(bidOnBehalfActions.requestCompanies());
            }
            if (!bwic.isAllToAll && user.hasRoles(roles.SellerTrader)) {
              dispatch(favoriteBrokerDealersActions.fetchFavoriteBrokerDealersRequest());
            }

            const nextBusinessDay = await nextBusinessDayPromise;
            const todayIsBusinessDay = await todayIsBusinessDayPromise;
            const currentYearHolidays = await currentYearHolidaysPromise;
            const nextYearHolidays = await nextYearHolidaysPromise;

            const minDate = todayIsBusinessDay
                ? moment().startOf('day').toDate()
                : moment(nextBusinessDay).startOf('day').toDate();

            const holidays = [...currentYearHolidays, ...nextYearHolidays];

            dispatch(storeMinSettleDate(minDate));
            dispatch(storeHolidays(holidays));
            dispatch(storeSecurities(bwic.securities));
        } catch (e) {
            dispatch(errorActions.criticalError(e));
        }
    };
}

function storeMinSettleDate(minimumDate) {
    return {
        type: actionTypes.STORE_MIN_SETTLE_DATE,
        minimumDate
    };
}

function storeSecurities(securities) {
    return {
        type: actionTypes.SELLER_BIDDING_STORE_SECURITIES,
        securities
    };
}

function storeHolidays(holidays) {
    return {
        type: actionTypes.SELLER_BIDDING_STORE_HOLIDAYS,
        holidays
    };
}

function cancelBwic(referenceName, reason) {
    return dispatch => {
        bwicService
            .cancel(referenceName, reason ? reason.trim() : reason)
            .then(
                () => dispatch(biddingActions.init(referenceName)),
                e => dispatch(errorActions.unexpectedError(e))
            );
    };
}

function reset() {
    return dispatch => {
        dispatch(dealerListPanelActions.reset());
        dispatch({ type: actionTypes.SELLER_BIDDING_RESET });
        dispatch(apiOperationActions.resetEvent(ApiOperationType.submitBidsOnBehalf));
        dispatch(bidOnBehalfActions.resetEdit());
        dispatch(dealerListActions.reset());
    };
}

function tradeAll() {
    return (dispatch, getState) => {
        const { securities } = getState().sellerBidding;
        const tradingPositions = securities.filter(s => biddingUtils.canTradePosition(s));

        dispatch(storeTradingPositions(tradingPositions));
    }
}

function trade(positionId) {
    return (dispatch, getState) => {
        const { securities } = getState().sellerBidding;
        const tradingPosition = securities.find(s => s.id === positionId);

        dispatch(storeTradingPositions([tradingPosition]));
    };
}

function tradeConfirm(visible) {
    return (dispatch, getState) => {
        let hasErrors = false;

        if (visible) {
            const { tradingPositions, holidays, minimumSettleDate } = getState().sellerBidding;
            const validationResult = validateTradingPositions(tradingPositions, minimumSettleDate, holidays);
            hasErrors = validationResult.some(r => r.errors);

            if (hasErrors) {
                dispatch({
                    type: actionTypes.SELLER_BIDING_STORE_TRADING_POSITIONS_VALIDATION_RESULT,
                    validationResult
                });
            }
        }

        if (!hasErrors) {
            dispatch({
                type: actionTypes.SELLER_BIDDING_TRADING_CONFIRMATION_VISIBLE,
                visible
            });
        }
    }
}

function storeTradingPositions(positions) {
    return (dispatch, getState) => {
        const { bwic } = getState().bidding;
        const tradingPositions = positions && positions.map(p => {
            const [positionWithActualBids] = getSecuritiesWithLatestBids({ securities: [p] });
            const [best] = biddingUtils.getBidsByLevel(positionWithActualBids.bids, BidLevel.Best);

            return {
                id: p.id,
                selected: true,
                tradingCompanyId: best.company.id,
                tradingCompanyIdentifier: biddingUtils.getBidCompanyIdentifier(best),
                tradingBidId: best.id,
                settleDate: bwic.defaultSettleDate,
                comment: '',
                errors: null
            };
        });

        dispatch({
            type: actionTypes.SELLER_BIDDING_STORE_TRADING_POSITIONS,
            tradingPositions
        });
    };
}

function completeBwic(bwicReferenceName) {
    return dispatch => {
        bwicService
            .setColorDistribution(bwicReferenceName, true)
            .then(
                () => dispatch(colorDistributionActions.setBwicColorDistribution(true)),
                e => dispatch(errorActions.unexpectedError(e))
            );
    };
}

function sendQuickFeedback(positionId, bidsList, feedbackType) {
    return (dispatch, getState) => {
        const { bwic } = getState().bidding;
        sendOnBehalfFeedback();
        sendRegularFeedback();

        function excludeJumpBallStage2Bids(bids){
            if (
                bwic.process.type === BwicProcessType.JumpBall &&
                bwic.process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended &&
                feedbackType === feedbackTypes.submitFinal &&
                bids?.some(b => b.stagedBiddingStatus === OpenBiddingStatus.stage1Ended)
            ) {
                return bids.filter(b => b.stagedBiddingStatus == null) // Take Stage1 bids only
            }
            return bids;
        }

        function sendOnBehalfFeedback() {
            const onBehalfBids = excludeJumpBallStage2Bids(bidsList.filter(b => b.onBehalf));
            if (onBehalfBids.length) {
                const bidIdList = onBehalfBids.map((b) => b.id);
                return biddingService
                    .sendOnBehalfQuickFeedback(bwic.referenceName, positionId, bidIdList, feedbackType)
                    .then(() => dispatch(feedbackSent(positionId, onBehalfBids, feedbackType)))
                    .catch(e => dispatch(errorActions.unexpectedError(e)));
            }
        }
        function sendRegularFeedback() {
            const bids = excludeJumpBallStage2Bids(bidsList.filter(b => !b.onBehalf));
            if (bids.length) {
                const bidIdList = bids.map((b) => b.id);
                return biddingService
                    .sendQuickFeedback(bwic.referenceName, positionId, bidIdList, feedbackType)
                    .then(() => dispatch(feedbackSent(positionId, bids, feedbackType)))
                    .catch(e => dispatch(errorActions.unexpectedError(e)));
            }
        }
    };
}

function sendBidRequest(positionId, companies, requestType) {
    return (dispatch, getState) => {
        const { bwic } = getState().bidding;

        biddingService
            .sendBidRequest(bwic.referenceName, positionId, companies.map(c => c.id), requestType)
            .catch(e => dispatch(errorActions.unexpectedError(e)));
    };
}

function feedbackSent(positionId, bids, feedbackType) {
    return {
        type: actionTypes.SELLER_BIDDING_QUICK_FEEDBACK_SENT,
        positionId,
        bids,
        feedbackType
    };
}

function tradingPositionSelectionChange(positionId, selected) {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.SELLER_BIDDING_TRADING_POSITION_SELECTION_CHANGE,
            positionId,
            selected
        });

        const { tradeAllChecked } = getState().sellerBidding;

        if (tradeAllChecked !== selected) {
            const { tradingPositions } = getState().sellerBidding;
            const tradingPositionIdList = Object.keys(tradingPositions).map(key => +key);

            if (tradingPositionIdList.every(id => tradingPositions[id].selected)) {
                dispatch(tradeAllSelectionChange(true));
            } else if (tradingPositionIdList.every(id => !tradingPositions[id].selected)) {
                dispatch(tradeAllSelectionChange(false));
            }
        }
    };
}

function tradeAllSelectionChange(tradeAllChecked) {
    return {
        type: actionTypes.SELLER_BIDDING_TRADING_TRADE_ALL_SELECTION_CHANGE,
        tradeAllChecked
    };
}

function submitTrade() {
    return (dispatch, getState) => {
        const { securities, tradingPositions } = getState().sellerBidding;
        const { bwic } = getState().bidding;

        const positions = Object
            .keys(tradingPositions)
            .map(positionId => +positionId)
            .filter(positionId => tradingPositions[positionId].selected)
            .map(positionId => {
                const p = tradingPositions[positionId];
                const position = securities.find(s => s.id === positionId);
                const bid = position.bids.find(b => b.id === p.tradingBidId && biddingUtils.getBidCompanyIdentifier(b) === p.tradingCompanyIdentifier);
                const companyBidLock = position.bidLocks && position.bidLocks.find(l => l.companyId === p.tradingCompanyId);

                return {
                    positionId,
                    buyerCompany: {
                        id: p.tradingCompanyId,
                    },
                    settleDate: p.settleDate,
                    bidId: bid.id,
                    bidLock: companyBidLock && companyBidLock.bidLock,
                    comment: p.comment
                };
            });

        dispatch(cancelTrade());

        biddingService
            .trade(bwic.referenceName, positions)
            .catch(e => {
                if (e.status === 409) {
                    dispatch(notificationActions.warningModal(
                        'New Bid Notification',
                        'New bids were received. Please try again or refresh the page.'
                    ));
                } else {
                    dispatch(errorActions.unexpectedError(e));
                }
            });
    };
}

function validateTradingPositions(tradingPositions, minimumSettleDate, holidays) {
    const minimumDate = moment(minimumSettleDate);
    const momentHolidays = holidays.map(h => moment(h).startOf('day'));

    return Object
        .keys(tradingPositions)
        .map(positionId => +positionId)
        .map(positionId => {
            if (!tradingPositions[positionId].selected) {
                return { positionId, errors: null };
            }

            const settleDate = validateTradingPositionSettleDate(tradingPositions[positionId].settleDate, minimumDate, momentHolidays);
            const errors = settleDate ? { settleDate } : null;

            return { positionId, errors };
        });
}

function validateTradingPositionSettleDate(settleDate, minimumDate, holidays) {

    if (settleDate === '') {
        return errorMessages.empty;
    } else if (typeof settleDate === 'undefined') {
        return errorMessages.invalidValue;
    } else if (minimumDate.diff(settleDate, 'days') > 0) {
        return errorMessages.dateShouldNotBeInPast;
    }

    const momentSettleDate = moment(settleDate);
    const weekday = momentSettleDate.weekday();

    

    if ([0, 6].some(d => d === weekday)) {
        return errorMessages.bwicDateShouldNotBeWeekend;
    } else if (holidays.some(h => h.isSame(momentSettleDate, 'day'))) {
        return errorMessages.bwicDateShouldNotBeHoliday;
    }

    return null;
}

function cancelTrade() {
    return dispatch => {
        dispatch(tradeConfirm(false));
        dispatch(storeTradingPositions(null));
    }
}

function tradingPositionCompanyChange(positionId, tradingCompanyIdentifier) {
    return {
        type: actionTypes.SELLER_BIDDING_TRADING_COMPANY_CHANGE,
        positionId,
        tradingCompanyIdentifier
    }
}

function tradingPositionBidChange(positionId, bidId) {
    return {
        type: actionTypes.SELLER_BIDDING_TRADING_BID_CHANGE,
        positionId,
        bidId
    };
}

function tradingPositionSettleDateChange(positionId, date) {
    return (dispatch, getState) => {
        const { tradingPositions } = getState().sellerBidding;
        const position = tradingPositions[positionId];

        let settleDateError = null;
        if (position.errors && position.errors.settleDate) {
            const { holidays, minimumSettleDate } = getState().sellerBidding;
            const minimumDate = moment(minimumSettleDate);
            const momentHolidays = holidays.map(h => moment(h).startOf('day'));

            settleDateError = validateTradingPositionSettleDate(date, minimumDate, momentHolidays);
        }

        dispatch({
            type: actionTypes.SELLER_BIDDING_TRADING_SETTLE_DATE_CHANGE,
            positionId,
            date,
            settleDateError
        });
    };
}

function tradingPositionCommentChange(positionId, comment) {
    return {
        type: actionTypes.SELLER_BIDDING_TADING_COMMENT_CHANGE,
        positionId,
        comment
    };
}

function setTargetLevel(securityId, value) {
    return {
        type: actionTypes.SELLER_BIDDING_SET_TARGET_LEVEL,
        payload: { securityId, value }
    }
}

function targetLevelRequest(securityId) {
    return {
        type: actionTypes.SELLER_BIDDING_TARGET_LEVEL_REQUEST,
        payload: { securityId }
    }
}

function targetLevelSuccess(securityId) {
    return {
        type: actionTypes.SELLER_BIDDING_TARGET_LEVEL_SUCCESS,
        payload: { securityId }
    }
}

function targetLevelFailure(securityId, error) {
    return {
        type: actionTypes.SELLER_BIDDING_TARGET_LEVEL_FAILURE,
        payload: { securityId, error }
    }
}

function sendTargetLevel(securityId) {
    return (dispatch, getState) => {
        const { bwic } = getState().bidding;
        const { securities, editTargetLevel } = getState().sellerBidding;
        const security = securities.find(s => s.id === securityId);
        const edit = editTargetLevel[securityId];
        const targetLevel = edit && numericUtils.isNumber(edit.targetLevel) && +edit.targetLevel;

        if (targetLevel != null && security) {
            if (targetLevel < constants.targetLevelRange.min || targetLevel > constants.targetLevelRange.max) {
                dispatch(setTargetLevel(securityId, undefined));
            } else if (security.targetLevel !== targetLevel) {
                dispatch(setTargetLevel(securityId, targetLevel.toFixed(4)));
                dispatch(targetLevelRequest(securityId));
                biddingService
                    .saveTargetLevel(bwic.referenceName, securityId, targetLevel)
                    .then(() => dispatch(targetLevelSuccess(securityId)))
                    .catch(e => dispatch(targetLevelFailure(securityId, e)));
            }
        }
    }
}

function showBidList(visible, positionId) {
    return dispatch => {
        if (visible) {
            dispatch(controlPanelActions.hide());
        }

        dispatch({
            type: actionTypes.SELLER_BIDDING_SHOW_BID_LIST,
            payload: {
                visible,
                positionId: visible ? positionId : undefined
            }
        });
    };
}

function toggleBidList(positionId) {
    return (dispatch, getState) => {
        const { bidList } = getState().sellerBidding;

        const visible = positionId !== bidList.positionId;
        dispatch(showBidList(visible, positionId));
    };
}

function markBidsShown(bidList, positionId, bwicReferenceName) {
    return {
        type: actionTypes.MARK_BIDS_SHOWN,
        bwicReferenceName,
        positionId,
        bidList
    };
}

